var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("combobox-form-dialog-button", {
        ref: "dialog",
        attrs: {
          items: _vm.qualificationTypeIds,
          "checked-items": _vm.checkedQualificationTypeIds,
          "combobox-label": "Qualification Type IDs",
          "button-label": _vm.$t(
            "console.platforms.mturk.qualifications.deleteQualTypesDialog.title"
          ),
          "button-icon": "mdi-trash-can",
          progress: _vm.progress,
        },
        on: {
          confirm: _vm.onConfirm,
          complete: _vm.onComplete,
          "dialog-open": _vm.onDialogOpen,
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "console.platforms.mturk.qualifications.deleteQualTypesDialog.title"
                      )
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "alert-body",
            fn: function ({ selectedItems }) {
              return [
                _c("v-card-text", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "console.platforms.mturk.qualifications.deleteQualTypesDialog.text",
                          { selectedItemsLength: selectedItems.length }
                        )
                      ) +
                      " "
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }