var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("page-toolbar", {
            attrs: {
              "previous-page-label": _vm.$t(
                "console.platforms.mturk.qualifications.toolbar.prevPageLabel"
              ),
              title: _vm.$t(
                "console.platforms.mturk.qualifications.toolbar.title"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "contents",
                fn: function () {
                  return [
                    _c("create-qualification-type-dialog-button", {
                      attrs: { client: _vm.client },
                      on: { complete: _vm.onComplete },
                    }),
                    _c("delete-qualification-types-dialog-button", {
                      attrs: {
                        client: _vm.client,
                        "qualification-type-ids": _vm.qualificationTypeIds,
                        "checked-qualification-type-ids":
                          _vm.checkedQualificationTypeIds,
                      },
                      on: { complete: _vm.onComplete },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "10" } },
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              dense: "",
              "show-select": "",
              "sort-desc": "",
              headers: _vm.qualHeaders,
              items: _vm.qualificationTypes,
              "single-expand": false,
              expanded: _vm.expanded,
              "item-key": "QualificationTypeId",
              loading: _vm.loading,
              "sort-by": "CreationTime",
            },
            on: {
              "update:expanded": function ($event) {
                _vm.expanded = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-card-title",
                      [
                        _c("v-spacer"),
                        _c("v-text-field", {
                          attrs: {
                            "single-line": "",
                            "hide-details": "",
                            "append-icon": "mdi-magnify",
                            label: _vm.$t(
                              "console.platforms.mturk.qualifications.table.searchLabel"
                            ),
                          },
                          model: {
                            value: _vm.search,
                            callback: function ($$v) {
                              _vm.search = $$v
                            },
                            expression: "search",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "item.QualificationTypeId",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "text-truncate",
                        staticStyle: { "max-width": "100px" },
                      },
                      [_vm._v(" " + _vm._s(item.QualificationTypeId) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "item.CreationTime",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.standardDateTimeFormat(item.CreationTime)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.checkedQualificationTypes,
              callback: function ($$v) {
                _vm.checkedQualificationTypes = $$v
              },
              expression: "checkedQualificationTypes",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }