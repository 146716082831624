<template>
    <tutti-dialog
        ref="dialog"
        max-width="800"
        name="mturk-qualification-create"
        :buttons="buttons"
        :overlay="overlay"
        @open="onDialogOpen"
    >
        <template #activator="{ on, attrs }">
            <v-btn
                text
                v-on="on"
                v-bind="attrs"
                color="indigo"
            >
                <v-icon left>mdi-plus</v-icon>
                {{$t('console.platforms.mturk.qualifications.createQualTypeDialog.title')}}
            </v-btn>
        </template>
        <template #title>
            {{$t('console.platforms.mturk.qualifications.createQualTypeDialog.title')}}
        </template>
        <template #overlay>
            <v-progress-circular indeterminate size="52" color="grey" />
        </template>
        <template #body>
            <v-card-text>
                <v-form v-model="valid">
                    <v-text-field
                        outlined
                        dense 
                        prepend-icon="mdi-label"
                        v-model="inputs.name"
                        :label="$t('console.platforms.mturk.qualifications.createQualTypeDialog.nameLabel')"
                        :rules="[rules.required]"
                    />
                    <v-text-field
                        outlined
                        dense
                        prepend-icon="mdi-message-text"
                        v-model="inputs.description"
                        :label="$t('console.platforms.mturk.qualifications.createQualTypeDialog.descLabel')"
                        :rules="[rules.required, rules.stringShorterThan(2000)]"
                    />
                    <v-switch
                        dense
                        hide-details
                        v-model="inputs.autoGranted"
                        color="indigo"
                        :label="$t('console.platforms.mturk.qualifications.createQualTypeDialog.autoGranted')"
                    />
                </v-form>
            </v-card-text>
        </template>
    </tutti-dialog>
</template>

<script>
import TuttiDialog from '@/components/ui/TuttiDialog'
import rules from '@/lib/input-rules'

export default {
    components: {
        TuttiDialog
    },
    data: () => ({
        rules,
        valid: false,

        inputs: {},
        overlay: {
            shown: false,
            attrs: {
                absolute: true,
                color: 'white',
            }
        }
    }),
    props: ['client'],
    computed: {
        buttons() {
                return [
                    {
                        label: this.$t('console.platforms.mturk.qualifications.createQualTypeDialog.buttons.cancel'),
                        attrs: {
                            color: 'grey darken-1',
                            text: true
                        },
                        on: {
                            click: () => { this.$refs.dialog.hide(); }
                        }
                    },
                    {
                        label: this.$t('console.platforms.mturk.qualifications.createQualTypeDialog.buttons.proceed'),
                        attrs: {
                            color: 'indigo darken-1',
                            disabled: !this.valid,
                            text: true,
                        },
                        on: {
                            click: this.createQualificationType
                        }
                    },
                ];
            }
    },
    methods: {
        async createQualificationType() {
            try {
                this.overlay.shown = true;
                await this.client.mturk.createQualificationType({
                    name: this.inputs.name,
                    description: this.inputs.description,
                    auto_granted: this.inputs.autoGranted,
                    qualification_type_status: 'Active'
                });
                this.$root.$emit('tutti-snackbar', 'success', 'Successfully created a qualification type');
                this.$emit('complete');
                this.$refs.dialog.hide();
            } catch (e) {
                this.$root.$emit('tutti-snackbar', 'error', `Error in creating a qualification: ${e}`);
                console.error(e);
            } finally {
                this.overlay.shown = false;
            }
        },
        onDialogOpen() {
            this.inputs = {
                name: '',
                description: '',
                autoGranted: false,
                qualificationTypeStatus: 'Active'
            };
        }
    }
}
</script>
